import React from 'react';
import {Helmet} from 'react-helmet';

import {findPageMeta} from "@/lib/utils";

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {
	path: string;

	title?: string;
	description?: string;
}

const Seo: React.FunctionComponent<Props> = (props: Props) => {
	const meta = findPageMeta(props.path);
	const title = props.title || (meta ? meta.title : "nologs-vpn");
	const description = props.description || (meta ? meta.description : "nologs-vpn");

	return (
		<>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description}/>
			</Helmet>
		</>
	);
};

export default Seo;
